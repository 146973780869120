import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import Layout from "../components/layout/Layout"
import Seo from "../components/util/Seo"

const NotFound = () => {
  const { language } = useI18next()
  return (
    <Layout>
      <div className="text-slate-500 flex-col text-center justify-center py-24 w-[65%]">
        <h1 className="pb-8">
          {language === "en"
            ? "Uh-Oh! That page doesn't exist!"
            : "Oh-Oh ! Cette page n'existe pas !"}
        </h1>
        <p>
          {language === "en"
            ? "Not to worry, you can email"
            : "Ne vous inquiétez pas, vous pouvez envoyer un e-mail à"}
          <a
            href="mailto: join@haply.co?subject=Inquiry"
            className="font-bold hover:font-extrabold hover:cursor-pointer"
          >
            {" "}
            info@haply.co
          </a>{" "}
          {language === "en"
            ? "if you require aditional support."
            : "si vous avez besoin d'une assistance supplémentaire."}
        </p>
      </div>
    </Layout>
  )
}

export default NotFound

export const Head = () => <Seo title="404 | Page Not Found" />
